//React Imports
import React from 'react'
import SideDrawer from './SideDrawer'
import HideOnScroll from './HideOnScroll'
import BackToTop from './BackToTop'
//Material Ui Imports
import { 
  AppBar, 
  Toolbar,
  Container, 
  Hidden, 
  List, 
  ListItem, 
  ListItemText,
  Fab} from '@material-ui/core'
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons'  
import { makeStyles } from '@material-ui/core/styles'
//Gatsby Imports
import { 
  Link, 
  useStaticQuery, 
  graphql} from 'gatsby'

const useStyles = makeStyles(theme =>({
  logo:{
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginRight: 'auto',
    },
  },
  logoImg:{
    width: 'auto',
    maxHeight: '40px',
    [theme.breakpoints.up('md')]: {
      maxHeight:'50px',
    }
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  linkText: {
    textTransform: 'uppercase',
    color: 'white',
    textDecoration: 'none !important'  
  },
  fabText: {
    color: theme.palette.primary.main,
  },
  navHoverColor :{
    "&:hover, &:focus, &:active" : {
      backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
  }
}),{index: 1})

const Header = () => {

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query navBarQuery {
      strapiHome {
        NavBar {
          Link {
            Texto
            Enlace
            ReactLink
          }
        }
        Logo{
          childImageSharp {
            fluid{
              src
            }
          }
        }
      }
    }
  `)
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar>
            <Container className={classes.nav}>
              <Link to ="/" className={ classes.logo }>
                <img src={ data.strapiHome.Logo.childImageSharp.fluid.src } alt="logo" className={ classes.logoImg }/>
              </Link>
              <Hidden smDown>
                <List 
                  component="nav" 
                  aria-labelledby="main navigation"
                  className={ classes.nav }
                >
                  {data.strapiHome.NavBar.Link.map((navLink, index)  => {
                    return !!navLink.ReactLink ?
                      <Link 
                        to={ navLink.Enlace } 
                        key={index}
                        className={classes.linkText}
                        //activeStyle={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
                      >
                        <ListItem 
                          button 
                          className = { classes.navHoverColor }
                        >
                          <ListItemText primary={navLink.Texto} />
                        </ListItem>
                      </Link> :
                      <Link 
                        to={ `/#${navLink.Enlace}` } 
                        key={index}
                        className={classes.linkText}
                        //activeStyle={{backgroundColor: 'rgba(0, 0, 0, 0.3)'}}
                      >
                        <ListItem 
                          button 
                          className = { classes.navHoverColor }
                        >
                        <ListItemText primary={navLink.Texto} />
                      </ListItem>
                    </Link> 
                    })
                  }
                </List>
              </Hidden>
              <Hidden mdUp>
                <SideDrawer navLinks={ data.strapiHome.NavBar.Link } />
              </Hidden>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />
      <BackToTop>
        <Fab 
          color="secondary" 
          size="large" 
          aria-label="scroll back to top"
          className= { classes.fabText }
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </BackToTop>
    </>
  )
}

export default Header
