import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#4D557B',
      },
      secondary: {
        main: '#f8d948',
      },
      gray:{
        background:'#e1e1ef',
        opacity:'rgba(53, 59, 86, 0.5)',
      }
    },
    timeline :{
      color:{
        job: '#9FA3BE', //#7bb2da
        achievement: '#54a6b7', //#5c5278 #54a6b7,
        start:'#f9e06c',
      },
      icons : {
        height: '35px',
      }
    }
  });
export default theme