import React from 'react'
import { Zoom, useScrollTrigger } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import{ FooterContext } from '../context/FooterProvider'

const useStyles = makeStyles(theme =>({
    btnBackToTop :{
        position: `fixed`,
        bottom: `50px`,
        right: `50px`,
        zIndex: `99`,
        [theme.breakpoints.up('sm')]: {
            right: `100px`,
        }
    }
}),{index: 1})


const BackToTop = ({ children }) => {
    const classes = useStyles()
    const { reachedBottom } = React.useContext(FooterContext)
    const trigger = useScrollTrigger()
    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
          "#back-to-top-anchor"
        )
        if (anchor) {
          anchor.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }

    return (
        <Zoom in={ reachedBottom ? false : trigger }>
            <div 
                onClick={handleClick} 
                role="presentation" 
                className={ classes.btnBackToTop }
            >
            {children}
            </div>
        </Zoom>
    )
}

export default BackToTop
