import React from 'react'
import { Menu as MenuIcon } from '@material-ui/icons'
import { 
  IconButton,
  List, 
  ListItem, 
  ListItemText,
  Drawer } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles"
import { Link } from 'gatsby'


const useStyles = makeStyles(theme =>({
  list: {
    width: 250,
  },
  linkText: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
  },
  drawerBack:{
    background: theme.palette.primary.dark
  }
}),{index: 1})


const SideDrawer = ({ navLinks }) => {


    const [opened, setOpened] = React.useState({ right: false })

    const classes = useStyles(); 

    const toggleDrawer = (anchor, open) => (event) => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return
        }
        setOpened({ [anchor]: open })
    }

    const sideDrawerList = anchor => (
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <List component="nav">
          {navLinks.map((navLink, index) => {
            return !!navLink.ReactLink ?
            <Link 
              to={ navLink.Enlace } 
              key={index} 
              className={ classes.linkText }
            >
              <ListItem button>
                <ListItemText primary={ navLink.Texto } />
              </ListItem>
            </Link> : 
            <Link 
              to={ `/#${navLink.Enlace}` }
              key={index} 
              className={ classes.linkText }
            >
            <ListItem button>
              <ListItemText primary={ navLink.Texto } />
            </ListItem>
          </Link>            
          })}
        </List>
      </div>
    )

    return (
        <>
          {/* Hamburger Button */}
          <IconButton
            edge="start"
            aria-label="menu"
            color="inherit"
            onClick={ toggleDrawer("right", true) } 
          >
            <MenuIcon />
          </IconButton>
          {/* Drawer */}

          <Drawer
            anchor="right"
            open={opened.right}
            onClose={toggleDrawer("right", false)}
            classes={{ paper: classes.drawerBack }}
          >
            {sideDrawerList("right")}
          </Drawer>

        </>
    )
}

export default SideDrawer
