import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { 
        BottomNavigation, 
        BottomNavigationAction, 
        Container, 
        Typography,
        Link as MaterialLink } from '@material-ui/core'
import {LinkedIn as LinkedInIcon , ContactMail as ContactMailIcon  } from '@material-ui/icons'
import { 
    Link, 
    useStaticQuery, 
    graphql} from 'gatsby'
import{ FooterContext } from '../context/FooterProvider'

const useStyles = makeStyles(theme => ({
    bottomNavigation: {
      maxWidth: 500,
      background: theme.palette.primary.dark
    },
    footer:{
        background: theme.palette.primary.dark,
        padding: '.5rem 0',
    },
    link:{
        color: theme.palette.primary.contrastText,
        textDecoration:'none !important'
    },
    copyright:{
        color: theme.palette.primary.contrastText
    },
    footerContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign:'center',
        flexFlow:'column-reverse',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-between',
            textAlign:'left',
            flexFlow:'row'
        }
    }   
  }),{index: 1})
const Footer = () => {
    const classes = useStyles()
    const { setReachedBottom } = React.useContext(FooterContext)
    const data = useStaticQuery(graphql`
    query footerQuery {
        strapiHome {
          Footer {
            Copyright
            footerlinks {
              Icon
              Link
              Text
              externalLink
            }
          }
        }
      }
  `)  

    React.useEffect(() => {
        const handleScroll = () => {
            const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
    
            if (bottom) {
                setReachedBottom(true)
            }
            else{
                setReachedBottom(false) 
            }
        }

        window.addEventListener('scroll', handleScroll, {
        passive: true
        })
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [setReachedBottom])
    return (
        <footer className={ classes.footer }>
            <Container className={ classes.footerContainer }>
                <Typography
                    component='p'
                    className={ classes.copyright }
                >
                    { data.strapiHome.Footer.Copyright }
                </Typography> 
                <BottomNavigation
                    showLabels
                    className={classes.bottomNavigation}
                    >
                    {
                        data.strapiHome.Footer.footerlinks && (
                            data.strapiHome.Footer.footerlinks.map((link, index) => {
                                switch(link.Icon){
                                    case 'linkedin':
                                        return !!link.externalLink ?
                                        <BottomNavigationAction
                                            component={ MaterialLink }
                                            label={ link.Text } 
                                            icon={<LinkedInIcon />}
                                            href={ link.Link }
                                            key={ index }
                                            className={ classes.link }
                                        /> :
                                        <BottomNavigationAction 
                                            component={ Link }
                                            label={ link.Text } 
                                            icon={<LinkedInIcon />} 
                                            to={ link.Link }
                                            key={ index }
                                            className={ classes.link }
                                        />
                                    case 'contact':
                                        return !!link.externalLink ?
                                        <BottomNavigationAction
                                            component={ MaterialLink }
                                            label={ link.Text } 
                                            icon={<ContactMailIcon />}
                                            href={ link.Link }
                                            key={ index }
                                            className={ classes.link }
                                        /> :
                                        <BottomNavigationAction 
                                            component={ Link }
                                            label={ link.Text } 
                                            icon={<ContactMailIcon />} 
                                            to={ link.Link }
                                            key={ index }
                                            className={ classes.link }
                                        />
                                    default:
                                        return null                                  
                                }
                            })
                        )
                    }
                </BottomNavigation>
            </Container>
        </footer>
    )
}

export default Footer
