import React from 'react';

export const FooterContext = React.createContext();

const FooterProvider = (props) => {
    const [reachedBottom, setReachedBottom] = React.useState(false)

    return (
        <FooterContext.Provider value={{reachedBottom, setReachedBottom}}>
            { props.children }
        </FooterContext.Provider>
    )
}

export default FooterProvider

